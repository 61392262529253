import React from 'react'
import Grid from '@material-ui/core/Grid';
import AngularLogo from '../images/angular-logo.svg';
import ChaiLogo from '../images/chai-logo.svg';
import CypressLogo from '../images/cypress-logo.svg';
import GitLogo from '../images/git-logo.svg';
import HtmlCssJsLogo from '../images/html-css-js-logo.svg';
import JiraLogo from '../images/jira-logo.svg';
import MochaLogo from '../images/mocha-logo.svg';
import NodeJsLogo from '../images/node-js-logo.svg';
import ReactLogo from '../images/react-logo.svg';
import SassLogo from '../images/sass-logo.svg';
import GruntLogo from '../images/grunt-logo.svg';
import GulpLogo from '../images/gulp-logo.svg';



const Skills = () => (
  <div style={{
    padding: '0 10px 10px 10px'
  }}>
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
    >
      <Grid item xs md={4}>
        <img src={ ReactLogo } className="skill-logo" alt="Skills" />
      </Grid>
      <Grid item xs md={4}>
        <img src={ HtmlCssJsLogo } className="skill-logo"  alt="Skills" />
      </Grid>
      <Grid item xs md={4}>
        <img src={ AngularLogo} className="skill-logo" alt="Skills" />
      </Grid>
    <Grid item xs md={4}>
      <img src={ GitLogo } className="skill-logo"  alt="Skills" />
    </Grid>
      <Grid item xs  md={4}>
        <img src={ NodeJsLogo } className="skill-logo"  alt="Skills" />
    </Grid>
    <Grid item xs md={4}>
    <img src={ JiraLogo } className="skill-logo"  alt="Skills" />
    </Grid>
      <Grid item xs md={4}>
        <img src={ GruntLogo } className="skill-logo small"  alt="Skills" />
      </Grid>
      <Grid item xs  md={4}>
        <img src={ GulpLogo } className="skill-logo xs-small"  alt="Skills" />
      </Grid>
      <Grid item xs md={4}>
        <img src={ SassLogo } className="skill-logo small"  alt="Skills" />
      </Grid>
    <Grid item xs md={4}>
    <img src={ ChaiLogo } className="skill-logo small" alt="Skills" />
    </Grid>
      <Grid item xs md={4}>
        <img src={ MochaLogo } className="skill-logo small"  alt="Skills" />
      </Grid>
      <Grid item xs md={4}>
        <img src={ CypressLogo } className="skill-logo"  alt="Skills" />
      </Grid>
    </Grid>
  </div>
)


export default Skills;
