import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Grid from '@material-ui/core/Grid';
import ReturnArrow from '../images/return-arrow.svg';
import Skills from '../components/skills';
import { Helmet } from 'react-helmet'


const About = () => (
    <Layout>
      <Helmet>
        <title>David Hildering [ About me ] - Freelance Front-end Developer</title>
        <meta name="description" content="Hi, My name is David Hildering. I am a freelance front-end developer from Utrecht."/>
      </Helmet>
      <Grid container
            className="link-list"
      >
        <Grid item xs={4}>
          <div>
            <Link to="/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Link  to="/projects/">Projects</Link>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Link to="/contact/">Contact</Link>
          </div>
        </Grid>
      </Grid>
      <Grid container
            direction="row"
            justify="center"
            spacing={8}>
        <div className='app-intro'>
        <Grid item xs={12}>
            <h2>ABOUT ME</h2>
        </Grid>
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <p>I am 36 years old and live with my wife and son in the city of Utrecht. </p>
              <p>Started with Front-end development in 2014 and still learning every day.
                Love to work together within a scrum team to create the best digital experience for the user.
              </p>
              <p>  Interested in working together? Drop me an <a className="link" href='mailto:mail@davidhildering.nl'>email</a> and I will get in touch asap. </p>
            </Grid>
            <Grid item md={6}>
              <Skills />
            </Grid>
          </Grid>
    </div>
      </Grid>
    </Layout>
)

export default About;
